import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: '#',
        title: 'Trip Manager',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        submenu: [
            {
                path: '/dashboard',
                title: 'Dashboard',
                iconType: 'fontawesome',
                icon: 'fas fa-list-alt',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN','TRIP_RESEARCHER','TRIP_ADMIN']
            },
            {
                path: '/questions',
                title: 'Manage Questions',
                iconType: 'fontawesome',
                icon: 'fas fa-question-circle',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN','TRIP_RESEARCHER','TRIP_ADMIN']

            },
            {
                path: '/participants',
                title: 'Participant Manager',
                iconType: 'fontawesome',
                icon: 'fas fa-user',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN','TRIP_RESEARCHER','TRIP_ADMIN']

            },
            {
                path: '/trips',
                title: 'Trip Data Explorer',
                iconType: 'fontawesome',
                icon: 'fas fa-database',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN','TRIP_RESEARCHER','TRIP_ADMIN']

            },
            {
                path: '/accounts',
                title: 'Accounts',
                iconType: 'fontawesome',
                icon: 'fas fa-list-ul',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN','TRIP_ADMIN']
            },
            {
                path: '/dataExport',
                title: 'Data Export Utility',
                iconType: 'fontawesome',
                icon: 'fas fa-external-link-square-alt',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN','TRIP_RESEARCHER','TRIP_ADMIN']
             }
        ],
        permitted_roles: ['ADMIN','TRIP_ADMIN','TRIP_RESEARCHER']
    },
    {
        path: '#',
        title: 'Administration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        submenu: [
                {
                    path: '/regulations',
                    title: 'Regulations',
                    iconType: 'fontawesome',
                    icon: 'fas fa-cogs',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN','REGULATION_MANAGER']
                }, {
                    path: '/default-regulations',
                    title: 'Default Regulations',
                    iconType: 'fontawesome',
                    icon: 'fas fa-cog',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN','REGULATION_MANAGER']
                }, 
                
                // {
                //     path: '#',
                //     title: 'AI Regulations',
                //     iconType: 'fontawesome',
                //     icon: 'fas fa-cog',
                //     iconTheme: 'outline',
                //     submenu: [{
                //         path: '/ai-regulations',
                //         title: 'AI Regulations',
                //         iconType: 'fontawesome',
                //         icon: 'fas fa-fish',
                //         iconTheme: 'outline',
                //         submenu: [],
                //         permitted_roles: ['ADMIN','REGULATION_MANAGER']
                //     }, {
                //         path: '/input-source',
                //         title: 'Input Source',
                //         iconType: 'fontawesome',
                //         icon: 'fas fa-clipboard-list',
                //         iconTheme: 'outline',
                //         submenu: [],
                //         permitted_roles: ['ADMIN','REGULATION_MANAGER']
                //     }],
                //     permitted_roles: ['ADMIN','REGULATION_MANAGER']
                // },
                
                {
                path: '#',
                title: 'Species',
                iconType: 'fontawesome',
                icon: 'fas fa-balance-scale',
                iconTheme: 'outline',
                submenu: [{
                    path: '/species',
                    title: 'Fish',
                    iconType: 'fontawesome',
                    icon: 'fas fa-fish',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN','REGULATION_MANAGER']
                }, {
                    path: '/state-records',
                    title: 'State Records',
                    iconType: 'fontawesome',
                    icon: 'fas fa-clipboard-list',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN','REGULATION_MANAGER']
                },
                {
                    path: '/measures',
                    title: 'Measures',
                    iconType: 'fontawesome',
                    icon: 'fas fa-ruler-combined',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN','REGULATION_MANAGER']
                },
                {
                    path: '/baits',
                    title: 'Baits & Lures',
                    iconType: 'fontawesome',
                    icon: 'fas fa-thermometer',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN','REGULATION_MANAGER']
                },
                {
                    path: '/fishing-method',
                    title: 'Fishing Methods',
                    iconType: 'fontawesome',
                    icon: 'fas fa-bars',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN']
                }                
                ],
                permitted_roles: ['ADMIN','REGULATION_MANAGER']
            },
            {
                    path: '/regions',
                    title: 'Boundaries',
                    iconType: 'fontawesome',
                    icon: 'fas fa-map',
                    iconTheme: 'outline',
                    submenu: [
                ],
                permitted_roles: ['ADMIN','REGULATION_MANAGER']
            },
            {
                path: '/users',
                title: 'Users',
                iconType: 'fontawesome',
                icon: 'fas fa-users',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN']
            }, 
            {
                path: '/user-feedback',
                title: 'Feedback',
                iconType: 'fontawesome',
                icon: 'fas fa-comment',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN']
            },
            {
                path: '/scans',
                title: 'Scans',
                iconType: 'fontawesome',
                icon: 'fas fa-search',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN']
            },
            {
                path: '/push-messages',
                title: 'Push Messages',
                iconType: 'fontawesome',
                icon: 'fas fa-sms',
                iconTheme: 'outline',
                submenu: [],
                permitted_roles: ['ADMIN']
            },
            {
                path: '#',
                title: 'Paywall',
                iconType: 'fontawesome',
                icon: 'fas fa-wallet',
                iconTheme: 'outline',
                submenu: [{
                    path: '/titles',
                    title: 'Titles',
                    iconType: 'fontawesome',
                    icon: 'fas fa-clipboard',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN']
                },{
                    path: '/features',
                    title: 'Features',
                    iconType: 'fontawesome',
                    icon: 'fas fa-list',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN']
                }, {
                    path: '/users-review',
                    title: 'User Reviews',
                    iconType: 'fontawesome',
                    icon: 'fas fa-star',
                    iconTheme: 'outline',
                    submenu: [],
                    permitted_roles: ['ADMIN']
                }],
                permitted_roles: ['ADMIN']
            }
        ],
        permitted_roles: ['ADMIN','REGULATION_MANAGER']
    }
]    