import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { AuthGuard } from './shared/guard/auth.guard';
import { JwtInterceptor } from './shared/interceptor/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataExportUtilityComponent } from './data-export-utility/data-export-utility.component';
import { RegionComponent } from './components/manage-regions/region/region.component';
import { AddEditRegionComponent } from './components/manage-regions/add-edit-region/add-edit-region.component';
import { SigninComponent } from './components/manage-signin/signin/signin.component';
import { ParticipantComponent } from './components/manage-participants/participant/participant.component';
import { AddEditParticipantComponent } from './components/manage-participants/add-edit-participant/add-edit-participant.component';
import { SpeciesComponent } from './components/manage-species/species/species.component';
import { AddEditSpeciesComponent } from './components/manage-species/add-edit-species/add-edit-species.component';
import { QuestionComponent } from './components/manage-questions/question/question.component';
import { TripsComponent } from './components/trips/trips-list/trips.component';
import { TripDetailsComponent } from './components/trips/trip-details/trip-details.component';
import { RegulationComponent } from './components/manage-regulations/regulation/regulation.component';
import { AddEditRegulationComponent } from './components/manage-regulations/add-edit-regulation/add-edit-regulation.component';
import { MeasuresComponent } from './components/manage-measures/measures/measures.component';
import { BaitsComponent } from './components/manage-baits/baits/baits.component';
import { ScansComponent } from './components/scans/scans/scans.component';
import { UsersComponent } from './components/manage-users/users/users.component';
import { StateRecordsComponent } from './components/manage-stateRecords/stateRecords/state-records.component';
import { DashboardComponent } from './components/manage-dashboard/dashboard/dashboard.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { AccountComponent } from './components/manage-accounts/account/account.component';
import { UserFeedbackComponent } from './components/manage-userFeedback/userFeedback/user-feedback.component';
import { FishingMethodComponent } from './components/manage-fishingMethod/fishingMethod/fishing-method.component';
import { DefaultRegulationComponent } from './components/manage-default-regulations/default-regulation/default-regulation.component';
import { PushMessageComponent } from './components/manage-pushMessages/pushMessage/push-message.component';
import { AIRegulationComponent } from './components/manage-aiRegulations/ai-regulation/ai-regulation.component';
import { AddEditAIRegulationComponent } from './components/manage-aiRegulations/add-edit-ai-regulation/add-edit-ai-regulation.component';
import { InputSourceComponent } from './components/manage-inputSource/input-source/input-source.component';
import { AddEditInputSourceComponent } from './components/manage-inputSource/add-edit-input-source/add-edit-input-source.component';
import { FeaturesComponent } from './components/manage-features/features/features.component';
import { PaywallTitlesComponent } from './components/manage-paywall-titles/add-edit-region/paywall-titles.component';
import { UsersReviewComponent } from './components/manage-user-reviews/user-reviews/user-reviews.component';

const appRoutes: Routes = [

    {  path: '',redirectTo: '/',pathMatch: 'full'},
  //  {  path: '', component: SigninComponent },
    {  path: 'signIn', component: SigninComponent },
    // {  path: 'dashboard1', component: CommonLayoutComponent,canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: CommonLayout_ROUTES},
    {  path: '', component: CommonLayoutComponent,canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
            { path: 'regions', component: RegionComponent},
            { path: 'regions/new', component: AddEditRegionComponent},
            { path: 'regions/:regionId', component: AddEditRegionComponent},
            { path: 'participants', component: ParticipantComponent},
            { path: 'participants/new', component: AddEditParticipantComponent},
            { path: 'participants/:participantId', component: AddEditParticipantComponent},
            { path: 'dataExport', component: DataExportUtilityComponent },
            { path: 'species', component: SpeciesComponent},
            { path: 'species/new', component: AddEditSpeciesComponent},
            { path: 'species/:speciesId', component: AddEditSpeciesComponent},
            { path: 'questions', component: QuestionComponent},
            { path: 'trips', component: TripsComponent},
            { path: 'trips/:tripId', component: TripDetailsComponent},
            { path: 'regulations', component: RegulationComponent},
            { path: 'regulations/new', component: AddEditRegulationComponent},
            { path: 'regulations/:regulationId', component: AddEditRegulationComponent},
            { path: 'measures', component: MeasuresComponent},
            { path: 'baits', component: BaitsComponent},
            { path: 'scans', component: ScansComponent},
            { path: 'users', component: UsersComponent},
            { path: 'state-records', component: StateRecordsComponent},
            { path: 'dashboard', component: DashboardComponent},
            { path: 'profile', component: UserEditComponent},
            { path: 'accounts', component: AccountComponent},
            { path: 'user-feedback', component: UserFeedbackComponent},
            { path: 'fishing-method', component: FishingMethodComponent},
            { path: 'default-regulations', component: DefaultRegulationComponent},
            { path: 'push-messages', component : PushMessageComponent},
            { path: 'ai-regulations', component : AIRegulationComponent},
            { path: 'ai-regulations/:regulationId', component: AddEditAIRegulationComponent},
            { path: 'input-source', component: InputSourceComponent},
            { path: 'input-source/new', component: AddEditInputSourceComponent},
            { path: 'input-source/:inputSourceId', component: AddEditInputSourceComponent},
            { path: 'features', component: FeaturesComponent},
            { path: 'users-review', component: UsersReviewComponent},
            { path: 'titles', component: PaywallTitlesComponent}
        ]}];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { 
            preloadingStrategy: PreloadAllModules,
            useHash: true,
            scrollPositionRestoration: 'enabled' 
        })
    ],
    exports: [
        RouterModule
    ],
    providers:[
        JwtInterceptor
    ]
})

export class AppRoutingModule {
}