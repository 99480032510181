import { FormGroup, FormControl, Validators } from "@angular/forms";

export class UsersReviewModel {

    id: string;
    userName: string;
    rating: number;
    review: String;
    title: String;
    imageUrl: string;
    removedImages: [string];
}

export class UsersReviewModelFormBuilder {
    usersReviewModel: UsersReviewModel = {
        id: null,
        userName: null,
        rating:0,
        review: null,
        title: null,
        imageUrl: null,
        removedImages: null
    }

    featuresForm: FormGroup = new FormGroup({
        id: new FormControl(this.usersReviewModel.id),
        userName: new FormControl(this.usersReviewModel.userName, [Validators.required]),
        rating: new FormControl(this.usersReviewModel.rating, [Validators.required]),
        review: new FormControl(this.usersReviewModel.review, [Validators.required]),
        title: new FormControl(this.usersReviewModel.title, [Validators.required]),
        imageUrl: new FormControl(this.usersReviewModel.imageUrl, [Validators.required])
    });

    constructor() {
        this.featuresForm.valueChanges.subscribe(val => {
            this.usersReviewModel.id = val.id;
            this.usersReviewModel.userName = val.userName;
            this.usersReviewModel.rating = val.rating;
            this.usersReviewModel.review = val.review;
            this.usersReviewModel.title = val.title;
            this.usersReviewModel.imageUrl = val.imageUrl;
        });
    }

    setModelVals(res: any) {
        this.featuresForm.controls.id.setValue(res.id),
            this.featuresForm.controls.userName.setValue(res.userName),
            this.featuresForm.controls.rating.setValue(res.rating),
            this.featuresForm.controls.review.setValue(res.review),
            this.featuresForm.controls.title.setValue(res.title),
            this.featuresForm.controls.imageUrl.setValue(res.imageUrl);
    }
}


