import { FormGroup, FormControl, Validators } from "@angular/forms";

export class FeaturesModel {

    id: string;
    name: string;
    description: string;
    imageUrl: string;
    removedImages: [string];
    title: string;
}

export class FeaturesModelFormBuilder {
    featuresModel: FeaturesModel = {
        id: null,
        name: null,
        description: null,
        imageUrl: null,
        removedImages: null,
        title: null
    }

    featuresForm: FormGroup = new FormGroup({
        id: new FormControl(this.featuresModel.id),
        name: new FormControl(this.featuresModel.name, [Validators.required]),
        description: new FormControl(this.featuresModel.description, [Validators.required]),
        imageUrl: new FormControl(this.featuresModel.imageUrl, [Validators.required]),
        title: new FormControl(this.featuresModel.title, [Validators.required])
    });

    constructor() {
        this.featuresForm.valueChanges.subscribe(val => {
            this.featuresModel.id = val.id;
            this.featuresModel.name = val.name;
            this.featuresModel.description = val.description;
            this.featuresModel.imageUrl = val.imageUrl;
            this.featuresModel.title = val.title;
        });
    }

    setModelVals(res: any) {
        this.featuresForm.controls.id.setValue(res.id),
            this.featuresForm.controls.name.setValue(res.name),
            this.featuresForm.controls.description.setValue(res.description),
            this.featuresForm.controls.affiliateLinks.setValue(res.affiliateLinks),
            this.featuresForm.controls.imageUrl.setValue(res.imageUrl);
            this.featuresForm.controls.title.setValue(res.title);
    }
}


