import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JwtInterceptor } from './shared/interceptor/token.interceptor';
import {AuthenticationService} from "./shared/services/authentication.service";
import { TableService } from './shared/services/table.service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { DisableButtonOnRequest } from './shared/directives/disable-form-submit.directive';
import { DataExportUtilityComponent } from './data-export-utility/data-export-utility.component';
import { AddEditRegionComponent } from './components/manage-regions/add-edit-region/add-edit-region.component';
import { RegionComponent } from './components/manage-regions/region/region.component';
import { SigninComponent } from './components/manage-signin/signin/signin.component';
import { ParticipantComponent } from './components/manage-participants/participant/participant.component';
import { AddEditParticipantComponent } from './components/manage-participants/add-edit-participant/add-edit-participant.component';
import { SpeciesComponent } from './components/manage-species/species/species.component';
import { AddEditSpeciesComponent } from './components/manage-species/add-edit-species/add-edit-species.component';
import { QuillModule } from 'ngx-quill'
import { AddEditQuestionComponent } from './components/manage-questions/add-edit-question/add-edit-question.component';
import { QuestionComponent } from './components/manage-questions/question/question.component';
import { TripsComponent } from './components/trips/trips-list/trips.component';
import { TripDetailsComponent } from './components/trips/trip-details/trip-details.component';
import { AddEditRegulationComponent } from './components/manage-regulations/add-edit-regulation/add-edit-regulation.component';
import { RegulationComponent } from './components/manage-regulations/regulation/regulation.component';
import { MeasuresComponent } from './components/manage-measures/measures/measures.component';
import { BaitsComponent } from './components/manage-baits/baits/baits.component';
import { AddEditBaitsComponent } from './components/manage-baits/add-edit-baits/add-edit-baits.component';
import { ScansComponent } from './components/scans/scans/scans.component';
import { UsersComponent } from './components/manage-users/users/users.component';
import { StateRecordsComponent } from './components/manage-stateRecords/stateRecords/state-records.component';
import { AddEditStateRecordsComponent } from './components/manage-stateRecords/add-edit-stateRecords/add-edit-state-records.component';
import { DashboardComponent } from './components/manage-dashboard/dashboard/dashboard.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { AccountComponent } from './components/manage-accounts/account/account.component';
import { AddEditUserFeedbackComponent } from './components/manage-userFeedback/add-edit-userFeedback/add-edit-user-feedback.component';
import { UserFeedbackComponent } from './components/manage-userFeedback/userFeedback/user-feedback.component';
import { FishingMethodComponent } from './components/manage-fishingMethod/fishingMethod/fishing-method.component';
import { DefaultRegulationComponent } from './components/manage-default-regulations/default-regulation/default-regulation.component';
import { PushMessageComponent } from './components/manage-pushMessages/pushMessage/push-message.component';
import { AIRegulationComponent } from './components/manage-aiRegulations/ai-regulation/ai-regulation.component';
import { AddEditAIRegulationComponent } from './components/manage-aiRegulations/add-edit-ai-regulation/add-edit-ai-regulation.component';
import { InputSourceComponent } from './components/manage-inputSource/input-source/input-source.component';
import { AddEditInputSourceComponent } from './components/manage-inputSource/add-edit-input-source/add-edit-input-source.component';
import { FeaturesComponent } from './components/manage-features/features/features.component';
import { PaywallTitlesComponent } from './components/manage-paywall-titles/add-edit-region/paywall-titles.component';
import { UsersReviewComponent } from './components/manage-user-reviews/user-reviews/user-reviews.component';

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        FullLayoutComponent,
        AddEditRegionComponent,
        RegionComponent,
        SigninComponent,
        DisableButtonOnRequest,
        ParticipantComponent,
        AddEditParticipantComponent,
        DataExportUtilityComponent,
        SpeciesComponent,
        AddEditSpeciesComponent,
        AddEditQuestionComponent,
        QuestionComponent,
        TripsComponent,
        TripDetailsComponent,
        RegulationComponent,
        AddEditRegulationComponent,
        MeasuresComponent,
        BaitsComponent,
        ScansComponent,
        UsersComponent,
        StateRecordsComponent,
        AddEditStateRecordsComponent,
        DashboardComponent,
        UserEditComponent,
        AccountComponent,
        AddEditUserFeedbackComponent,
        UserFeedbackComponent,
        FishingMethodComponent,
        DefaultRegulationComponent,
        PushMessageComponent,
        AIRegulationComponent,
        AddEditAIRegulationComponent,
        InputSourceComponent,
        AddEditInputSourceComponent,
        FeaturesComponent,
        UsersReviewComponent,
        PaywallTitlesComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgZorroAntdModule,
        AppRoutingModule,
        TemplateModule,
        SharedModule,
        NgChartjsModule,
        ReactiveFormsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        QuillModule.forRoot(),
        DragDropModule,
        AngularFireModule.initializeApp(environment.firebaseConfig)
    ],
    providers: [
        { 
            provide: NZ_I18N,
            useValue: en_US, 
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi   : true,
        },
        ThemeConstantService,
        AuthenticationService,
        TableService,
        DisableButtonOnRequest,
        AddEditRegionComponent,
        AddEditAIRegulationComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
