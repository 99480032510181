import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { RegionFormBuilder, RegionModel } from 'src/app/shared/models/region-model';
import { CountryService } from 'src/app/shared/services/country.service';
import { StateService } from 'src/app/shared/services/state.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { TitlesFormBuilder, TitlesModel } from 'src/app/shared/models/paywall-titles-model';
import { PaywallTitlesService } from 'src/app/shared/services/paywall-titles.service';

@Component({
  selector: 'paywall-titles',
  templateUrl: './paywall-titles.component.html',
  styleUrls: ['./paywall-titles.component.css']
})
export class PaywallTitlesComponent implements OnInit {

    titles: TitlesFormBuilder = new TitlesFormBuilder();
    titlesModel: TitlesModel = this.titles.titlesModel;
    titlesForm: FormGroup = this.titles.titlesForm;
    processSubmitForm: boolean = false;
    titlesDataList: any[] = [];

    constructor(private titlesService: PaywallTitlesService,
      private messageService: NzMessageService
    ) {
        this.titlesForm = this.titles.titlesForm;
    }

    ngOnInit() {
        this.fetchTitlesData();
    }

    fetchTitlesData() {
      this.titlesService.fetchAllTitles().subscribe(
        (res: any) => {
          this.titlesDataList = res;
          this.titles.setModelVals(this.titlesDataList);
          console.log(this.titlesDataList);
        },
        (err) => {
          console.error('Error fetching titles:', err);        
        });
    }

  submitTitlesForm() {
    if (this.titlesForm.valid) {
      const titleMsgId = this.messageService.loading('Saving Titles...').messageId;
      const titlesDTOList: TitlesModel[] = [];

       // Iterate over form controls to build DTO list
      Object.keys(this.titlesForm.controls).forEach(controlName => {
          const existingTitle = this.titlesDataList.find(title => title.name === controlName);
          const titleDTO: TitlesModel = {
              id: existingTitle ? existingTitle.id : null,
              name: controlName, 
              value: this.titlesForm.value[controlName]
          };
          titlesDTOList.push(titleDTO);
      });
        
      this.titlesService.saveTitles(titlesDTOList).subscribe((res: any) => {
            setTimeout(() => {
              this.messageService.remove(titleMsgId);
            }, 700);
        },
        (error: any) => {
          console.error('Error saving/updating titles:', error);
        }
      );
    }
  }
    

    forwardToTitlesPage() {
    }
}
