import { Component, OnInit } from '@angular/core';
import { NzMessageService, UploadFilter, UploadFile, NzModalService } from 'ng-zorro-antd';
import { TableService } from 'src/app/shared/services/table.service';
import { FeaturesService } from 'src/app/shared/services/features.service';
import { FeaturesModel } from 'src/app/shared/models/features-model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {
  featuresList: any;
  displayData: any;
  sortAttribute: any;
  featuresModel: FeaturesModel;
  formHeaderText: String;
  isVisible: boolean = false;
  fileList: UploadFile[] = [];
  imageFileList: UploadFile[] = [];
  submitButton: boolean = true;
  file: UploadFile;
  previewImage = '';
  previewVisible = false; 
  featuresLoading = false;
  title = '';

  constructor(private featuresService: FeaturesService,
    private messageService: NzMessageService,
    private tableService: TableService,
    private modelService: NzModalService) {
    this.featuresModel = new FeaturesModel();
  }

  ngOnInit() {
    this.fetchAllFeatures();
  }

  /**
   * Get list of Baits & Lures
   */
  fetchAllFeatures() {
    this.featuresLoading = true;
    this.featuresService.fetchAllFeatures().subscribe(res => {
      this.featuresLoading = false;
      this.featuresList = res;
      this.displayData = this.featuresList;
      this.title = this.displayData[0].title;
    });
  }

  /**
  * Sort List
  * @param sortAttribute 
  */
  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    this.displayData = this.tableService.sort(sortAttribute, this.featuresList);
  }

  /**
   * Open Model Window for Add and Update
   * @param baitsData 
   */
  openModelWindow(featuresData: any) {
    this.isVisible = true;
    if (featuresData == null) {
      this.formHeaderText = 'Create New Features';
      this.featuresModel = new FeaturesModel();
      this.imageFileList = [];
      this.submitButton = true;
      
    } else {
      this.formHeaderText = 'Update Features';
      const featuresDataClone = Object.assign({}, featuresData);
      this.featuresModel = featuresDataClone;
      if (this.featuresModel.imageUrl) {
        let uploadedFile: UploadFile = {
          url: this.featuresModel.imageUrl,
          uid: '',
          name: '',
          size: 10,
          type: '',
          status: 'done'
        };
        this.imageFileList = [uploadedFile];
      }
      this.submitButton = false;
    }
  }

  /**
   * Event on Cancel Button
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * Allowed image type for upload image
   */
  imageFileFilter: UploadFilter[] = [
    {
      name: 'type',
      fn: (uploadFileList: UploadFile[]) => {
        var file = uploadFileList[0];
        if (uploadFileList[0].name && !(uploadFileList[0].name.toLowerCase().endsWith("jpg") || uploadFileList[0].name.toLowerCase().endsWith("png") ||
          uploadFileList[0].name.toLowerCase().endsWith("jpeg") || uploadFileList[0].name.toLowerCase().endsWith("gif") ||
          uploadFileList[0].name.toLowerCase().endsWith("bmp"))) {
          this.messageService.error("Only image file upload is supported", { nzDuration: 2000 });
          return [];
        }
        return uploadFileList;
      }
    }];

  /**
   * Event on Submit Button
   */
  onSubmit(form?: NgForm) {
    var featureDtoBlob = new Blob([JSON.stringify(this.featuresModel)], {
      type: 'application/json'
    });
    let formData: FormData = new FormData();
    formData.append('featuresDto', featureDtoBlob);
    this.imageFileList.forEach((file: any) => {
      formData.append('file', file.originFileObj);
    });

    if (this.featuresModel.id != null) {
      var updateMsgId = this.messageService.loading('Updating...').messageId;
      this.featuresService.updateFeatures(formData).subscribe(res => {
        this.messageService.remove(updateMsgId);
        this.modelService.closeAll();
        this.fetchAllFeatures();
      })

    } else {
      this.submitButton = true;
      const loadingData = this.messageService.loading('Saving ...').messageId;
      this.featuresService.saveFeatures(formData).subscribe((res) => {
        this.messageService.remove(loadingData);
        this.modelService.closeAll();
        this.fetchAllFeatures();
      });
    }
    this.handleCancel(form);
  }

/**
 * Preview image
 */
handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
}

  /**
   * On change image
   * @param info 
   */
  handleChange(info: any): void {
    if (info.file.status === 'uploading') {
      this.getBase64(info.file.originFileObj, (img: string) => {
        info.file.url = img;
        info.file.status = 'done';
        return true;
      });
    }
    this.OnSubmitButton();
  }

  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  /**
   * Remove Image
   */
  deleteImage = (file: any): void => {
    if (!this.featuresModel.removedImages) {
      this.featuresModel.removedImages = [file.name];
    } else {
      this.featuresModel.removedImages.push(file.name);
    }
    this.imageFileList = this.imageFileList.filter(f => f.name !== file.name);
    this.OnSubmitButton();
  }

/**
 * Delete Confirmation model
 * @param id
 */
  DeleteFeatutresForm(id): void {
    this.modelService.confirm({
      nzTitle: 'Delete this Featutres ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteFeatures(id),
      nzCancelText: 'No'
    });
  }

  /**
   * Delete Features
   * @param id
   */
  deleteFeatures(id) {
    var deleteMsgId = this.messageService.loading("Deleting...").messageId;
    this.featuresService.deleteFeatures(id).subscribe((res) => {
      this.messageService.remove(deleteMsgId);
      this.removeFeaturesFromList(id);
    });
  }

  /**
   * Remove Features from list
   * @param id 
   */
  removeFeaturesFromList(id) {
    this.featuresList.forEach((featutres, index) => {
      if (featutres.id === id) {
        this.featuresList.splice(index, 1);
        return false;
      }
    });
    this.displayData = this.featuresList;
  }

  /**
   * Submit button enable - disable on model
   */
  OnSubmitButton() {
    if (this.featuresModel.name != null && this.featuresModel.description != null && this.imageFileList.length != 0 &&
      this.featuresModel.name != "" && this.featuresModel.description != "") {
          this.submitButton = false;
    } else {
          this.submitButton = true;
    }
  }

  saveTitle() {
    // Used update api to save or update title only.
    this.title = this.title.trim();
    if(this.featuresModel.title != this.title){
      this.featuresModel = {
          id: null, 
          name: null,
          description: null,
          imageUrl: null,
          title: this.title,
          removedImages: null
      };

      var featureDtoBlob = new Blob([JSON.stringify(this.featuresModel)], {
          type: 'application/json'
      });
      let formData: FormData = new FormData();
      formData.append('featuresDto', featureDtoBlob);

      var titleMsgId = this.messageService.loading('Saving Title...').messageId;
      this.featuresService.updateFeatures(formData).subscribe(res => {
        setTimeout(() => {
          this.messageService.remove(titleMsgId);
          this.modelService.closeAll();
        }, 700);
      });
  }
} 

handleCancel(form?: NgForm): void {
  this.isVisible = false;
  if (form) {
    this.resetForm(form);
  }
}

resetForm(form: NgForm): void {
  form.resetForm(); 
  this.featuresModel = {
    name: '',
    description: '',
    imageUrl: '',
    title: '',
    removedImages: null,
    id: ''
  };
  this.imageFileList = []; 
  this.submitButton = true; 
}
}
