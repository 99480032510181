import { FormGroup, FormControl, Validators } from "@angular/forms";

export class TitlesModel {
    id: string;
    name: string;
    value: string;
}

export class TitlesFormBuilder {
    titlesModel: TitlesModel = {
        id: null,
        name: null,
        value: null
    }

    titlesForm: FormGroup = new FormGroup({
        title1: new FormControl('', [Validators.required]),
        title2: new FormControl('', [Validators.required])
    });

    constructor() {
        this.titlesForm.valueChanges.subscribe(val => {
            this.titlesModel['title1'] = val.title1;
            this.titlesModel['title2'] = val.title2;
        });
    }

    setModelVals(titles: TitlesModel[]) {
        titles.forEach(title => {
            if (title.name === 'title1') {
                this.titlesForm.controls['title1'].setValue(title.value);
            } else if (title.name === 'title2') {
                this.titlesForm.controls['title2'].setValue(title.value);
            }
        });
    }
}

