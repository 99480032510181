import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { TitlesModel } from '../models/paywall-titles-model';

@Injectable({
  providedIn: 'root'
})

export class PaywallTitlesService {
  private titlesUrl = 'api/v1/admin/titles';

  constructor(private httpRequest: HttpRequestService) {
  }

  fetchAllTitles(): Observable<Response> {
    return this.httpRequest.get(this.titlesUrl);
  }

  saveTitles(titlesDTOList: TitlesModel[]): Observable<Response> {
    return this.httpRequest.post(this.titlesUrl, titlesDTOList);
  }

  updateTitles(formData: FormData): Observable<Response> {
    return this.httpRequest.filePut(this.titlesUrl, formData);
  }

  deleteTitles(id: String): Observable<Response> {
    return this.httpRequest.delete(this.titlesUrl + "/" + id);
  }
}

