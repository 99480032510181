import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { RegionFormBuilder, RegionModel } from 'src/app/shared/models/region-model';
import { CountryService } from 'src/app/shared/services/country.service';
import { StateService } from 'src/app/shared/services/state.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { InputSourceFormBuilder, InputSourceModel } from 'src/app/shared/models/input-source-model';
import { InputSourceService } from 'src/app/shared/services/inputSource.service';

@Component({
  selector: 'app-add-edit-input-source',
  templateUrl: './add-edit-input-source.component.html',
  styleUrls: ['./add-edit-input-source.component.css']
})
export class AddEditInputSourceComponent implements OnInit {

  inputSource: InputSourceFormBuilder = new InputSourceFormBuilder();
  inputSourceModel: InputSourceModel = this.inputSource.inputSourceModel;
  inputSourceForm: FormGroup = this.inputSource.inputSourceForm;
  countries = [];
  states = [];
  loadingStates: boolean = false;
  kmlFile: any = null;
  fileList: UploadFile[] = [];
  fileName;
  isStateLoaded:boolean= false
  inputSourceLinkList: any = [];
  inputLinkValue: any = '';
  disableSubmitButton:boolean;
  disableCancelButton:boolean; 
  isEditMode: boolean = false;

  ngOnInit() {
    this.isEditMode = this.route.snapshot.params.inputSourceId ? true : false;
    if(this.isEditMode){
      if(this.inputSourceModel.id){
          this.countries.push(this.inputSourceModel.country);
          this.states.push(this.inputSourceModel.state);
          this.countryChange(this.inputSourceModel.country, false);
      }
    }
    this.fetchCountries();
  }

  constructor(private countryService: CountryService,
    private stateService: StateService,
    private regionService: RegionService,
    private messageService: NzMessageService,
    private inputSourceService: InputSourceService,
    private router: Router,
    private route: ActivatedRoute,

   ) {
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
          this.inputSource.setModelVals(this.router.getCurrentNavigation().extras.state.inputSource);
          this.inputSourceLinkList = this.router.getCurrentNavigation().extras.state.inputSource.source;
        }
      });
  }

 compareFn(obj1: RegionModel, obj2: RegionModel) {
   return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
 }


  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  /**
   * Add and update the region form
   */
  submitInputSourceForm() {
    var createMsgId = this.messageService.loading("Adding...").messageId;
    this.disableSubmitButton = true;
    this.disableCancelButton = true;
    
    this.inputSourceModel.state.country = {};
    this.inputSourceModel.source = this.inputSourceLinkList;

    this.inputSourceService.addInputSource(this.inputSourceModel).subscribe(res => {
      this.router.navigate(['/input-source']);
      this.messageService.remove(createMsgId);
    },
      err => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
    }
    )    
}

  /**
   * Fetch all the countries
   */
 async fetchCountries() {
    this.countries = await this.countryService.fetchCountries();
  }

  /**
   * When the country change states will be fetched
   */
  countryChange($event, resetSelectedState) {
    if ($event != null) {
      this.loadingStates = true;
      if(resetSelectedState){
        this.states = [];
        this.inputSourceModel.state = null;
        this.inputSourceForm.controls['state'].reset();
      }
      this.stateService.fetchStatesOfCountry($event.id).subscribe((res) => {
        this.states = res;
        this.loadingStates = false;
      });
    }
  }

  /**
   * find region by id
   */
  findRegionById(regionId) {
    var loadMessage = this.messageService.loading("Loading...", {nzDuration:0}).messageId;
    this.regionService.findById(regionId).subscribe((res) => {
      this.messageService.remove(loadMessage);
    });
  }

  /**
   * This method is responsible for redirecting to regions page
   */
  forwardToRegionsPage(){
    this.router.navigate(['/regions']);
  }

  addInputSourceToList(){
    if(this.inputLinkValue.length > 0 || this.inputLinkValue !==''){
      this.inputSourceLinkList.push(this.inputLinkValue);
      this.inputLinkValue = '';
    }
  }

  removeLink(link1){
    this.inputSourceLinkList = this.inputSourceLinkList.filter(link => link != link1);
  }

}
